<template>
  <!-- Dialog Remove Idea -->
  <ib-dialog
    :visible="visible"
    :show-close="false"
    width="550px"
    top="8vh"
    empty-header
    @close="hideDialogRemoveIdea"
  >
    <!-- Dialog Content -->
    <ib-dialog-confirmation-content
      :image="require('@/assets/img/illustrations/trash.svg')"
      :title="$t('dialogs.removeIdea.title')"
      :subtitle="$t('dialogs.removeIdea.subtitle')"
      :show-illustration="false"
    />
    <!-- /Dialog Content -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :loading="loading"
        :submit-btn-text="$t('dialogs.removeIdea.yesDeleteIt')"
        :cancel-btn-text="$t('dialogs.removeIdea.noJustKidding')"
        space-between-buttons
        variant-button="red"
        @cancel="hideDialogRemoveIdea"
        @submit="removeIdea"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
  <!-- /Dialog Remove Idea -->
</template>

<script>
export default {
  name: 'DialogRemoveIdea',

  props: {
    visible: {
      default: false,
      type: Boolean
    },

    hideDialogRemoveIdea: {
      type: Function,
      default: function () {}
    },

    removeIdea: {
      type: Function,
      default: function () {}
    }
  },

  data () {
    return {
      loading: false
    }
  }
}
</script>
