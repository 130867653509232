<template>
  <!-- Dialog Copy Idea -->
  <ib-dialog
    :visible="visible"
    :show-close="false"
    width="550px"
    empty-header
    @close="hideDialogCopyIdea"
  >
    <!-- Dialog Content -->
    <ib-dialog-confirmation-content
      :image="require('@/assets/img/illustrations/delete-note.svg')"
      :title="$t('dialogs.copyIdea.title')"
    />
    <!-- /Dialog Content -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :submit-btn-text="$t('dialogs.copyIdea.yesCopyIdea')"
        :cancel-btn-text="$t('dialogs.copyIdea.noJustKidding')"
        :loading="loading"
        space-between-buttons
        @cancel="hideDialogCopyIdea"
        @submit="copyIdea"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
  <!-- /Dialog Copy Idea -->
</template>

<script>
export default {
  name: 'DialogCopyIdea',

  props: {
    visible: {
      default: false,
      type: Boolean
    },

    hideDialogCopyIdea: {
      type: Function,
      default: function () {}
    },

    copyIdea: {
      type: Function,
      default: function () {}
    }
  },

  data () {
    return {
      loading: false
    }
  }
}
</script>
