<template>
  <div class="w-100">
    <template v-if="view === 'list'">
      <div
        class="idea-collection-card-list d-flex justify-between flex-column flex-md-row"
        :class="{'selected': isActiveIdea}"
        @click="onSelectIdea"
      >

        <div class="card-left d-flex justify-content-center align-items-md-center">
          <span
            v-if="isActiveIdea"
            class="label-left"
          >
            <p>{{ $t('pages.ideaCollection.ideaCard.active') }}</p>
          </span>
          <span
            v-if="isIdeaDisabled"
            class="label-left disabled"
          >
            <p>{{ $t('disabled') }}</p>
          </span>
          <span class="left-img__holder">
            <ib-idea-avatar
              :idea="idea"
              size="md"
            />

          </span>
        </div>

        <div class="card-middle d-flex flex-column align-items-start justify-content-center flex-grow-1 text-center text-md-left my-2 my-md-0">
          <p class="card-title m-0" :title="idea.name">
            {{ idea.name }}
          </p>
          <p class="idea-subcategory" :title="idea.catalogueIdea ? idea.catalogueIdea.name : 'Other ' + idea.catalogueCategory.name">
            {{ idea.catalogueIdea ? idea.catalogueIdea.name : 'Other ' + idea.catalogueCategory.name }}
          </p>
        </div>

        <div class="card-right d-flex justify-content-center">
          <div
            v-if="isTemplateAdmin && idea.template"
            class="template-manager-list custom-padding align-self-center text-center"
          >
            {{ $t('template.usedAsTemplate') }} - {{ idea.templateLanguage.toUpperCase() }}
          </div>
          <div v-else class="member-flex d-flex align-items-center justify-content-center justify-content-md-end align-self-center">
            <div v-for="collaborator in collaborators" :key="collaborator.id" class="avatar-item">
              <ib-user-avatar
                :user="collaborator.user"
                size="md"
                :show-default-image="collaborator.id > 0"
              />
            </div>

            <ib-tooltip
              v-if="isOwner"
              popper-class="plan-popper"
              :content="$t('inviteCollaborators')"
            >
              <el-button
                type="plus-round-xs"
                icon="el-icon-plus"
                @click.stop="$emit('invite', idea)"
              />
            </ib-tooltip>
          </div>
          <el-dropdown
            ref="actionsDropdown"
            class="action-items"
            trigger="click"
            @command="handleIdeaCommand"
          >
            <span
              class="el-dropdown-link"
              @click="preventEmitting"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 341.333 341.333" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M170.667 85.333c23.573 0 42.667-19.093 42.667-42.667C213.333 19.093 194.24 0 170.667 0S128 19.093 128 42.667c0 23.573 19.093 42.666 42.667 42.666zM170.667 128C147.093 128 128 147.093 128 170.667s19.093 42.667 42.667 42.667 42.667-19.093 42.667-42.667S194.24 128 170.667 128zM170.667 256C147.093 256 128 275.093 128 298.667c0 23.573 19.093 42.667 42.667 42.667s42.667-19.093 42.667-42.667C213.333 275.093 194.24 256 170.667 256z"/></svg>
            </span>
            <el-dropdown-menu slot="dropdown" class="action-dropdown">
              <el-dropdown-item
                :command="{ name: 'edit', ideaId: idea.id }"
                :disabled="isIdeasLimitExceeded || isIdeaDisabled"
              >
                {{ canEdit ? $t('edit') : $t('view') }}
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ name: 'settings', ideaId: idea.id }"
                :disabled="isIdeasLimitExceeded || isIdeaDisabled"
              >
                {{ canEdit ? $t('settingsMenu') : $t('view') }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="canCopy"
                :command="{ name: 'copy', ideaId: idea.id }"
                :disabled="isIdeasLimitReached || isIdeaDisabled"
              >
                {{ $t('copy') }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="isOwner"
                :command="{ name: 'delete', ideaId: idea.id }"
                :disabled="isIdeaDisabled"
              >
                {{ $t('delete') }}
              </el-dropdown-item>
              <el-dropdown-item v-if="!isOwner" :command="{ name: 'leave', ideaId: idea.id }">
                {{ $t('leave') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <i v-if="isGuest" :title="$t('userRoles.guest')" class="role-icon role-icon-list ib-icon ib-icon-eyes-cartoon h5 mb-2" />
        <i v-if="isBuddy" :title="$t('userRoles.buddy')" class="role-icon role-icon-list ib-icon ib-icon-group-task h5 mb-2" />
      </div>
    </template>
    <template v-else>
      <div
        class="idea-collection-card flex-grow-1"
        :class="{'selected': isActiveIdea}"
        @click="onSelectIdea"
      >
        <div class="card-top">
          <span
            v-if="isActiveIdea"
            class="label-left"
          >
            <p>{{ $t('pages.ideaCollection.ideaCard.active') }}</p>
          </span>
          <span
            v-if="isIdeaDisabled"
            class="label-left disabled"
          >
            <p>{{ $t('disabled') }}</p>
          </span>
          <span class="top-img__holder">
            <span
              :style="idea.ideaSettings.image ? cardImage: { backgroundImage: imageUrl(idea.catalogueIdea ? idea.catalogueIdea.cover : null) || imageUrl(idea.catalogueCategory.cover) }"
            />
            <i v-if="isGuest" :title="$t('userRoles.guest')" class="role-icon ib-icon ib-icon-eyes-cartoon h2 mr-1 mb-1" />
            <i v-if="isBuddy" :title="$t('userRoles.buddy')" class="role-icon ib-icon ib-icon-group-task h2 mr-1 mb-1" />
          </span>
          <el-dropdown
            ref="actionsDropdown"
            class="action-items"
            trigger="click"
            @command="handleIdeaCommand"
          >
            <span
              class="el-dropdown-link"
              @click="preventEmitting"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 341.333 341.333" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M170.667 85.333c23.573 0 42.667-19.093 42.667-42.667C213.333 19.093 194.24 0 170.667 0S128 19.093 128 42.667c0 23.573 19.093 42.666 42.667 42.666zM170.667 128C147.093 128 128 147.093 128 170.667s19.093 42.667 42.667 42.667 42.667-19.093 42.667-42.667S194.24 128 170.667 128zM170.667 256C147.093 256 128 275.093 128 298.667c0 23.573 19.093 42.667 42.667 42.667s42.667-19.093 42.667-42.667C213.333 275.093 194.24 256 170.667 256z"/></svg>
            </span>
            <el-dropdown-menu slot="dropdown" class="action-dropdown">
              <el-dropdown-item
                :command="{ name: 'edit', ideaId: idea.id }"
                :disabled="isIdeasLimitExceeded || isIdeaDisabled"
              >
                {{ canEdit ? $t('edit') : $t('view') }}
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ name: 'settings', ideaId: idea.id }"
                :disabled="isIdeasLimitExceeded || isIdeaDisabled"
              >
                {{ canEdit ? $t('settingsMenu') : $t('view') }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="canCopy"
                :command="{ name: 'copy', ideaId: idea.id }"
                :disabled="isIdeasLimitReached || isIdeaDisabled"
              >
                {{ $t('copy') }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="isOwner"
                :command="{ name: 'delete', ideaId: idea.id }"
                :disabled="isIdeaDisabled"
              >
                {{ $t('delete') }}
              </el-dropdown-item>
              <el-dropdown-item v-if="!isOwner" :command="{ name: 'leave', ideaId: idea.id }">
                {{ $t('leave') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <div
            class="card-icon"
            :style="{backgroundImage: imageUrl(idea.catalogueIdea ? idea.catalogueIdea.icon : null) || imageUrl(idea.catalogueCategory.icon)}"
          />
        </div>

        <div class="card-bottom">
          <div class="card-title-wrapper">
            <p class="card-title">
              {{ idea.name }}
            </p>
          </div>
          <!-- include sub category -->
          <p class="idea-subcategory">
            {{ idea.catalogueIdea ? idea.catalogueIdea.name : 'Other ' + idea.catalogueCategory.name }}
          </p>
          <!--{{ idea.catalogueIdea }}-->
          <div
            v-if="isTemplateAdmin && idea.template"
            class="template-manager custom-padding"
          >
            {{ $t('template.usedAsTemplate') }} - {{ idea.templateLanguage.toUpperCase() }}
          </div>
          <div v-else class="member-flex">
            <div v-for="collaborator in collaborators" :key="collaborator.id" class="avatar-item">
              <ib-user-avatar :user="collaborator.user" size="sm" :show-default-image="collaborator.id > 0" />
            </div>

            <ib-tooltip
              v-if="isOwner"
              popper-class="plan-popper"
              :content="$t('inviteCollaborators')"
            >
              <el-button
                type="plus-round-xs"
                icon="el-icon-plus"
                @click.stop="$emit('invite', idea)"
              />
            </ib-tooltip>
          </div>
          <div class="idea-data">
            <p v-if="hasOwner">
              {{ $t('pages.ideaCollection.ideaCard.owner') }} {{ owner.firstName }} {{ owner.lastName }}
            </p>
            <p :title="`${$t('pages.ideaCollection.ideaCard.lastEdited')} ${$moment(idea.updatedAt).fromNow()}`">
              {{ $t('pages.ideaCollection.ideaCard.lastEdited') }} {{ $moment(idea.updatedAt).fromNow() }}
            </p>
            <p>{{ $t('pages.ideaCollection.ideaCard.created') }} {{ $moment(idea.createdAt).format('MMM D, YYYY') }}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import UserRoleEnum from '@/constants/UserRoleEnum'

import { getImageBySize } from '@/helpers/imageSizeHelper'
import { mapGetters } from 'vuex'

export default {
  name: 'IdeaCard',
  mixins: [
    MixinIdeaRoles
  ],

  props: {
    idea: {
      type: Object,
      required: true
    },
    role: {
      type: Number,
      required: true
    },
    owner: {
      type: Object,
      required: false,
      default: () => {}
    },
    view: {
      type: String,
      validator: value => ['grid', 'list'].includes(value),
      default: 'grid'
    }
  },

  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },

  computed: {
    ...mapGetters('user', [
      'isTemplateAdmin',
      'ideasCount',
      'ideasInPlan'
    ]),

    ...mapGetters('idea', ['getIdeaId']),

    isActiveIdea () {
      return this.getIdeaId === this.idea.id
    },

    isIdeasLimitExceeded () {
      return !this.isTemplateAdmin && this.ideasCount > this.ideasInPlan
    },

    isIdeasLimitReached () {
      return !this.isTemplateAdmin && this.ideasCount >= this.ideasInPlan
    },

    isIdeaDisabled () {
      return !this.idea.ownerSubscription.subscribed
    },

    cardImage () {
      const img = this.idea.ideaSettings ? (this.idea.ideaSettings.image ? getImageBySize(this.idea.ideaSettings.image.sizes, 'medium') : null) : null

      return img ? 'background-image: url(' + img + ')' : ''
    },

    collaborators () {
      const limit = this.view === 'grid' ? 5 : 3
      const count = this.idea.collaborators.length

      if (count > (limit + 1)) {
        const difference = count - limit
        const slicedCollaborators = this.idea.collaborators.slice(0, limit)
        slicedCollaborators.push({
          id: 0,
          user: {
            firstName: '+',
            lastName: difference.toString()
          }
        })

        return slicedCollaborators
      }

      return this.idea.collaborators
    },

    isGuest () {
      return this.role === UserRoleEnum.GUEST
    },

    isBuddy () {
      return this.role === UserRoleEnum.BUDDY
    },

    isOwner () {
      return this.role === UserRoleEnum.OWNER
    },

    canCopy () {
      return this.isOwner || this.isBuddy
    },

    hasOwner () {
      if (!this.owner) return false

      return !!Object.keys(this.owner).length
    }
  },

  methods: {
    imageUrl (img) {
      if (img) {
        return `url('${this.baseUrl}${img.path}${img.filename}')`
      } else {
        return ''
      }
    },

    handleIdeaCommand (command) {
      switch (command.name) {
        case 'edit':
          if (!this.isIdeaDisabled) this.$emit('edit', command.ideaId)
          break
        case 'settings':
          if (this.isIdeaDisabled) return
          if (this.isActiveIdea) {
            this.$emit('settings', command.ideaId)

            return
          }

          this.$emit('settingsSwitch', command.ideaId)
          break
        case 'delete':
          this.$emit('delete', command.ideaId)
          break
        case 'copy':
          this.$emit('copy', command.ideaId)
          break
        case 'leave':
          this.$emit('leave', command.ideaId)
          break
      }
    },

    onSelectIdea () {
      if (!this.isIdeaDisabled) this.$emit('click', this.idea.id)
    },

    preventEmitting (e) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-item:not(:first-child) {
  margin-left: -20px;
  z-index: 1;
}

.template-manager {
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  background-color: #ffba00;
  border-radius: 5px;
  padding: 2px 10px;
}

.custom-padding {
  padding: 9px 0 10px;
}

.role-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  color: silver;
}

.role-icon-list {
  margin-right: 13px;
}

.idea-collection-card-list {
  position: relative;
  padding: 10px 0;
  min-height: 80px;
  box-sizing: border-box;
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.25s;

  .action-items {
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    padding: 10px;

    svg {
      transition: all 0.25s;
      padding: 5px;
      border-radius: 50%;
      background-color: transparent;
      width: 16px;
      height: 16px;

      &:hover {
        cursor: pointer;
        transition: all 0.25s;
        background-color: rgba($color-white, .9);

        path {
          transition: all 0.25s;
          opacity: 1;
        }
      }

      path {
        transition: all 0.25s;
        opacity: 0.15;
        fill: $color-navy;
      }
    }
  }

  &:hover {
    cursor: pointer;
    transition: all 0.25s;
    transform: translateY(-2px) translateX(-2px);
    box-shadow: 4px 5px 7px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.06);
  }
}

.card-left {
  position: relative;
  .left-img__holder {
    margin: 0 20px;
  }

  .label-left {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-yellow;
    border-radius: 0 100px 100px 0;
    padding: 3px 6px;
    z-index: 10;

    &.disabled {
      background-color: indianred;
    }

    p {
      opacity: 1;
      margin: 0 auto;
      font-size: 12px;
      font-family: outfit-semi-bold, sans-serif;
      text-transform: uppercase;
      color: #fff;
    }
  }
  &.default {
    background-image: asset("img/icons/story-mode/customer.svg");
    background-size: 80px;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.card-middle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .card-title {
    font-size: 16px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .idea-subcategory {
    font-size: 14px;
    opacity: 0.5;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.card-right {
  @include media-breakpoint-up($md) {
    margin-right: 35px;
  }
  .template-manager-list {
    min-width: 110px;
    min-height: 17px;
    font-size: 12px;
    text-transform: uppercase;
    color: white;
    background-color: #ffba00;
    border-radius: 5px;
    padding: 2px 10px;
  }

  .member-flex {
    width: 100%;
    .el-icon-plus {
      font-weight: bold;
    }
  }
}
</style>
