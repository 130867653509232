<template>
  <ib-dialog
    :visible="visible"
    :closable="false"
    top="8vh"
    @close="hideDialogSwitchIdea"
  >
    <!-- Dialog Content -->
    <ib-dialog-confirmation-content
      :image="require('@/assets/img/illustrations/swap.svg')"
      :title="$t('dialogs.switchIdea.title')"
    />
    <!-- /Dialog Content -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :submit-btn-text="$t('dialogs.switchIdea.yesTakeMeThere')"
        :cancel-btn-text="$t('dialogs.switchIdea.nope')"
        space-between-buttons
        :loading="loading"
        @cancel="hideDialogSwitchIdea"
        @submit="switchIdea"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
</template>

<script>
export default {
  name: 'DialogSwitchIdea',

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    hideDialogSwitchIdea: {
      type: Function,
      default: function () {}
    },
    switchIdea: {
      type: Function,
      default: function () {}
    }
  },

  data () {
    return {
      loading: false
    }
  }
}
</script>
