<template>
  <v-app>
    <div class="d-flex justify-content-between flex-column flex-md-row align-md-center mb-3 mb-md-0">
      <p class="tab-description text-regular">
        {{ title }}
      </p>
      <div class="d-flex justify-center align-center">
        <v-text-field
          v-model="searchValue"
          outlined
          dense
          :placeholder="$t('search')"
          hide-details
          clearable
          height="44px"
          class="search-text-field"
        >
          <template #prepend-inner>
            <v-icon>{{ icons.mdiMagnify }}</v-icon>
          </template>
        </v-text-field>
        <view-switcher
          class="ml-1"
          :active-view="view"
          @activate-view="onChangeView"
        />
      </div>
    </div>
  </v-app>
</template>

<script>
import ViewSwitcher from '@/components/_v2/ViewSwitcher'
import { mdiMagnify } from '@mdi/js'

export default {
  name: 'IdeaCollectionListHeader',
  components: {
    ViewSwitcher
  },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      validator: value => typeof value === 'string' || value === null,
      required: true
    },
    view: {
      type: String,
      validator: value => ['grid', 'list'].includes(value),
      default: 'grid'
    }
  },
  data () {
    return {
      activeView: 'grid',
      icons: { mdiMagnify }
    }
  },

  computed: {
    searchValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onChangeView (view) {
      this.$emit('onChangeView', view)
    }
  }
}

</script>

<style lang="scss" scoped>
.tab-description {
  opacity: 0.5;
  font-size: 18px;
  margin: 30px 0 10px;
}

.search-text-field {
  ::v-deep {
    .v-input__prepend-inner {
      z-index: 1;
    }
    fieldset {
      background-color: #fff;
      border-color: #E3E3E3;
    }
  }
}
</style>
