import i18n from '@/i18n'

export default {
  email: [
    {
      required: true,
      message: i18n.t('validationMessages.fieldIsRequired', { field: i18n.t('emailAddress') }),
      trigger: 'blur'
    },
    {
      type: 'email',
      message: i18n.t('validationMessages.validEmailAddress'),
      trigger: 'blur'
    }
  ],
  note: [
    {
      max: 500,
      message: i18n.t('validationMessages.fieldCantBeLonger', { field: i18n.t('invitationMessage'), max: 500 }),
      trigger: 'blur'
    }
  ]
}
