<template>
  <el-row :gutter="20">
    <el-col :md="12" class="mb-2 mb-md-0">
      <div
        :class="{'cursor-pointer': value}"
        @click="changeRole(UserRoleEnum.GUEST)"
      >
        <div class="mb-2 d-flex justify-content-between pr-4">
          <div class="d-flex align-items-center">
            <i class="ib-icon ib-icon-eyes-cartoon h2 mr-2" />
            <h5 class="m-0">
              {{ $t('userRoles.guest') }}
            </h5>
          </div>
          <el-radio
            v-if="value"
            v-model="content"
            class="invite-radio"
            :label="UserRoleEnum.GUEST"
          />
        </div>
        <div>
          {{ $t('pages.users.inviteGuestDescription') }}
        </div>
      </div>
    </el-col>
    <el-col :md="12">
      <div
        :class="{'cursor-pointer': value}"
        @click="changeRole(UserRoleEnum.BUDDY)"
      >
        <div class="mb-2 d-flex justify-content-between pr-4">
          <div class="d-flex align-items-center">
            <i class="ib-icon ib-icon-group-task h2 mr-2" />
            <h5 class="m-0">
              {{ $t('userRoles.buddy') }}
            </h5>
          </div>
          <el-radio
            v-if="value"
            v-model="content"
            class="invite-radio"
            :label="UserRoleEnum.BUDDY"
          />
        </div>
        <div>
          {{ $t('pages.users.inviteBuddyDescription') }}
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import UserRoleEnum from '@/constants/UserRoleEnum'

export default {
  name: 'AccessChooser',

  props: {
    value: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      UserRoleEnum
    }
  },

  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  methods: {
    changeRole (role) {
      if (this.disable) return
      this.content = role
    }
  }
}
</script>

<style scoped lang="scss">
.invite-radio {
  ::v-deep .el-radio__label {
    display: none;
  }
}
</style>
