<template>
  <div id="idea-collection">
    <div class="container-1200">
      <div class="content">
        <header
          :class="{'ideas-limit-reached': isIdeasLimitReached}"
          class="d-flex align-items-center justify-content-end justify-content-md-between"
        >
          <div class="d-none d-md-block">
            <h1>
              {{ $t('pages.ideaCollection.title') }}
              <sup v-if="isTemplateAdmin" class="template-manager">
                {{ $t('template.manager') }}
              </sup>
            </h1>
            <p class="text-lead">
              {{ $t('pages.ideaCollection.subtitle') }}
            </p>
          </div>
          <!--          <p-->
          <!--            v-if="!isTemplateAdmin"-->
          <!--            class="text-lead text-limit"-->
          <!--            :class="{'limit-on': isIdeasLimitReached}"-->
          <!--          >-->
          <!--            <template v-if="isIdeasLimitReached">-->
          <!--              <ib-button-->
          <!--                class="text-uppercase px-4"-->
          <!--                font-family="regular"-->
          <!--                size="sm"-->
          <!--                @click="$router.push({ name: 'payment' })"-->
          <!--              >-->
          <!--                {{ $t('upgrade') }}-->
          <!--              </ib-button>-->
          <!--            </template>-->
          <!--          </p>-->
        </header>

        <el-tabs
          v-model="activeTab"
          stretch
        >
          <el-tab-pane
            name="myIdeas"
          >
            <span slot="label" class="text-uppercase">
              {{ $t('pages.ideaCollection.myIdeas') }}
              <span
                v-if="getIdeasCount"
                class="idea-count"
                :class="{ 'limit-reached': isIdeasLimitReached }"
              >
                {{ getIdeasCount }} / {{ ideasInPlan }}
              </span>
            </span>
            <div class="ideas-filled">
              <idea-collection-list-header
                v-model="searchValueIdeas"
                :title="$t('pages.ideaCollection.myIdeasDescription')"
                :view="ideaCollectionView"
                @onChangeView="onChangeView"
              />
              <el-row
                v-if="ideaCollectionView === 'grid'"
                :gutter="20"
                class="p-1"
              >
                <ib-draggable
                  :list="getIdeas"
                  class="d-flex flex-wrap"
                  @change="onChangeIdeaOrder"
                  @start="onStartIdeaOrder"
                >
                  <template #header>
                    <el-col
                      class="d-flex width-xl-20 mb-3"
                      :sm="8"
                      :lg="6"
                    >
                      <add-new-card
                        class="add-min-height width-xl-20"
                        :title="$t('pages.ideaCollection.createNewIdea')"
                        @click="redirectToSelectIdea"
                      />
                    </el-col>
                  </template>
                  <el-col
                    v-for="idea in searchResultsIdeas"
                    :key="idea.id"
                    :sm="8"
                    :lg="6"
                    class="d-flex width-xl-20 draggable mb-3"
                  >
                    <idea-card
                      ref="ideaCard"
                      :idea="idea"
                      :role="UserRoleEnum.OWNER"
                      :view="ideaCollectionView"
                      @click="showDialogSwitchIdea"
                      @edit="onSwitchIdea"
                      @delete="showDialogRemoveIdea"
                      @copy="showDialogCopyIdea"
                      @invite="showDialogInviteUser"
                      @settings="showDialogSettingsIdea"
                      @settingsSwitch="onSettingsSwitch"
                    />
                  </el-col>
                </ib-draggable>
              </el-row>
              <el-row
                v-if="ideaCollectionView === 'list'"
                :gutter="20"
                class="p-1"
              >
                <ib-draggable
                  :list="getIdeas"
                  class="d-flex flex-wrap"
                  @change="onChangeIdeaOrder"
                  @start="onStartIdeaOrder"
                >
                  <template #header>
                    <el-col
                      class="d-flex mb-3"
                      :sm="24"
                      :lg="12"
                    >
                      <add-new-card
                        horizontal
                        class="p-1"
                        :title="$t('pages.ideaCollection.createNewIdea')"
                        @click="redirectToSelectIdea"
                      />
                    </el-col>
                  </template>
                  <el-col
                    v-for="idea in searchResultsIdeas"
                    :key="idea.id"
                    :sm="24"
                    :lg="12"
                    class="d-flex draggable mb-3"
                  >
                    <idea-card
                      ref="ideaCard"
                      :idea="idea"
                      :role="UserRoleEnum.OWNER"
                      :view="ideaCollectionView"
                      @click="showDialogSwitchIdea"
                      @edit="onSwitchIdea"
                      @delete="showDialogRemoveIdea"
                      @copy="showDialogCopyIdea"
                      @invite="showDialogInviteUser"
                      @settingsSwitch="onSettingsSwitch"
                    />
                  </el-col>
                </ib-draggable>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane
            name="shared"
            :disabled="!hasSharedIdeas"
          >
            <span slot="label" class="text-uppercase">
              {{ $t('pages.ideaCollection.shared') }}
              <span v-if="getSharedIdeasCount" class="idea-count">{{ getSharedIdeasCount }}</span>
            </span>
            <div class="ideas-filled">
              <idea-collection-list-header
                v-model="searchValueSharedIdeas"
                :title="$t('pages.ideaCollection.sharedDescription')"
                :view="ideaCollectionView"
                @onChangeView="onChangeView"
              />
              <el-row :gutter="20" class="d-flex flex-wrap">
                <el-col
                  v-for="invitation in searchResultsSharedIdeas"
                  :key="invitation.id"
                  :sm="24"
                  :lg="12"
                  class="d-flex mb-3"
                  :class="{'width-xl-20' :ideaCollectionView === 'grid'}"
                >
                  <idea-card
                    :idea="invitation.idea"
                    :role="invitation.role"
                    :owner="invitation.owner"
                    :view="ideaCollectionView"
                    @click="showDialogSwitchIdea"
                    @edit="onSwitchIdea"
                    @delete="showDialogRemoveIdea"
                    @copy="showDialogCopyIdea"
                    @invite="showDialogInviteUser"
                    @leave="showDialogLeaveIdea"
                    @settings="showDialogSettingsIdea"
                    @settingsSwitch="onSettingsSwitch"
                  />
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>

        <dialog-switch-idea
          ref="dialogSwitchIdea"
          :visible="dialogSwitchIdeaVisible"
          :hide-dialog-switch-idea="hideDialogSwitchIdea"
          :switch-idea="onSwitchIdea"
        />
        <dialog-remove-idea
          ref="dialogRemoveIdea"
          :visible="dialogRemoveIdeaVisible"
          :hide-dialog-remove-idea="hideDialogRemoveIdea"
          :remove-idea="removeIdea"
        />
        <dialog-copy-idea
          ref="dialogCopyIdea"
          :visible="dialogCopyIdeaVisible"
          :hide-dialog-copy-idea="hideDialogCopyIdea"
          :copy-idea="copyIdea"
        />
        <dialog-leave-idea
          ref="dialogLeaveIdea"
          :visible="dialogLeaveIdeaVisible"
          :hide-dialog-leave-idea="hideDialogLeaveIdea"
          :confirm="onLeaveIdea"
        />

        <dialog-idea-invite-collaborator
          :visible="dialogIdeaInviteCollaboratorVisible"
          :idea="ideaToInvite"
          @close="hideDialogIdeaInviteCollaborator"
        />

        <bottom-back-block />
      </div>
    </div>
  </div>
</template>

<script>
import AddNewCard from '@/components/_v2/AddNewCard'
import BottomBackBlock from '@/components/_v2/Common/BottomBackBlock'
import DialogCopyIdea from '@/views/Home/IdeaCollection/Dialogs/DialogCopyIdea'
import DialogIdeaInviteCollaborator from '@/views/Home/InvitedUsers/Dialogs/DialogIdeaInviteCollaborator'
import DialogLeaveIdea from '@/views/Home/IdeaCollection/Dialogs/DialogLeaveIdea'
import DialogRemoveIdea from '@/views/Home/IdeaCollection/Dialogs/DialogRemoveIdea'
import DialogSwitchIdea from './Dialogs/DialogSwitchIdea'
import IdeaCard from '@/views/Home/Components/IdeaCard'
import UserRoleEnum from '@/constants/UserRoleEnum'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import IdeaCollectionListHeader from '@/views/Home/Components/IdeaCollectionListHeader'
import { scrollToTop } from '@/helpers/scrollHelpers'

export default {
  name: 'IdeaCollection',

  components: {
    AddNewCard,
    BottomBackBlock,
    DialogCopyIdea,
    DialogIdeaInviteCollaborator,
    DialogLeaveIdea,
    DialogRemoveIdea,
    DialogSwitchIdea,
    IdeaCard,
    IdeaCollectionListHeader
  },

  data () {
    return {
      searchValueIdeas: '',
      searchValueSharedIdeas: '',
      UserRoleEnum,
      activeTab: null,
      dialogSwitchIdeaVisible: false,
      dialogRemoveIdeaVisible: false,
      dialogCopyIdeaVisible: false,
      dialogLeaveIdeaVisible: false,
      dialogIdeaInviteCollaboratorVisible: false,
      ideaToSwitchId: null,
      ideaToRemoveId: null,
      ideaToCopyId: null,
      ideaToLeaveId: null,
      ideaToInvite: null,
      openIdeaSettingsAfterSwitch: false,
      settings: false
    }
  },

  computed: {
    ...mapGetters('ideaCollection', [
      'getIdeas',
      'getIdeasCount',
      'getSharedIdeas',
      'getSharedIdeasCount'
    ]),
    ...mapGetters('user', [
      'ideasCount',
      'ideasInPlan',
      'isSubscribed',
      'isFreeTrial',
      'isUsersLimitExceeded',
      'isTemplateAdmin'
    ]),

    ideaCollectionView () {
      return this.$store.state.itemsView.ideaCollectionView
    },

    isIdeasLimitReached () {
      return !this.isTemplateAdmin && this.ideasCount >= this.ideasInPlan
    },

    isIdeasLimitExceeded () {
      return !this.isTemplateAdmin && this.ideasCount > this.ideasInPlan
    },

    activeIdeaId () {
      return this.$store.state.idea.id
    },

    hasSharedIdeas () {
      return this.getSharedIdeas.length
    },

    searchResultsIdeas () {
      if (this.searchValueIdeas !== '' && this.searchValueIdeas) {
        return this.getIdeas.filter(item => item.name.toUpperCase().includes(this.searchValueIdeas.toUpperCase()))
      }

      return this.getIdeas
    },

    searchResultsSharedIdeas () {
      if (this.searchValueSharedIdeas !== '' && this.searchValueSharedIdeas) {
        return this.getSharedIdeas.filter(item => item.idea.name.toUpperCase().includes(this.searchValueSharedIdeas.toUpperCase()))
      }

      return this.getSharedIdeas
    }
  },

  watch: {
    getSharedIdeasCount: {
      handler (newValue) {
        if (newValue === 0) {
          this.activeTab = 'myIdeas'
        }
      },
      immediate: true
    }
  },

  mounted () {
    this.getAllIdeas()
    this.activeTab = location.hash === '#shared' ? 'shared' : 'myIdeas'
  },

  methods: {
    ...mapActions('ideaCollection', [
      'getAllIdeas',
      'deleteIdea'
    ]),
    ...mapActions('idea', [
      'switchIdea',
      'duplicateIdea',
      'leaveIdea',
      'updateOrderNumber'
    ]),
    ...mapMutations('ui', [
      'setDialogUpgradeState',
      'setDialogIdeaSettingsState'
    ]),

    onChangeView (view) {
      this.$store.commit('itemsView/setIdeaCollectionView', view)
    },

    onStartIdeaOrder () {
      this.$refs.ideaCard.forEach(card => {
        card.$refs.actionsDropdown.visible = false
      })
    },

    onChangeIdeaOrder (data) {
      this.updateOrderNumber({
        type: 'idea',
        data,
        oldOrderData: this.getIdeas,
        mutation: 'ideaCollection/setIdeas'
      })
    },

    redirectToSelectIdea () {
      if (this.isIdeasLimitReached || !this.isSubscribed) {
        this.setDialogUpgradeState(true)

        return
      }
      this.$router.push({ name: 'select-idea' })
    },

    showDialogSwitchIdea (ideaId) {
      if (this.isIdeasLimitExceeded && this.activeIdeaId !== ideaId) {
        this.setDialogUpgradeState(true)
      } else {
        if (this.activeIdeaId === ideaId) {
          this.dialogSwitchIdeaVisible = false
          if (this.ideasCount <= this.ideasInPlan) {
            const id = ideaId || this.ideaToSwitchId

            if (this.ideaToSwitchId) this.hideDialogSwitchIdea()
            if (!id) return

            this.switchIdea(id)
              .then(_ => {
                this.$router.push({ name: 'home' })
              })
          }
        } else {
          this.dialogSwitchIdeaVisible = true
          this.ideaToSwitchId = ideaId
        }
      }
    },

    onSettingsSwitch (ideaId) {
      if (this.isIdeasLimitExceeded && this.activeIdeaId !== ideaId) {
        this.setDialogUpgradeState(true)

        return
      }

      this.dialogSwitchIdeaVisible = true
      this.ideaToSwitchId = ideaId
      this.openIdeaSettingsAfterSwitch = true
      this.settings = true
    },

    hideDialogSwitchIdea () {
      this.dialogSwitchIdeaVisible = false

      this.ideaToSwitchId = null
      this.openIdeaSettingsAfterSwitch = false
      this.settings = false
    },

    showDialogRemoveIdea (ideaId) {
      this.dialogRemoveIdeaVisible = true

      this.ideaToRemoveId = ideaId
    },

    hideDialogRemoveIdea () {
      this.dialogRemoveIdeaVisible = false

      this.ideaToRemoveId = null
    },

    showDialogCopyIdea (ideaId) {
      this.dialogCopyIdeaVisible = true

      this.ideaToCopyId = ideaId
    },

    hideDialogCopyIdea () {
      this.dialogCopyIdeaVisible = false

      this.ideaToCopyId = null
    },

    showDialogLeaveIdea (ideaId) {
      this.dialogLeaveIdeaVisible = true

      this.ideaToLeaveId = ideaId
    },

    hideDialogLeaveIdea () {
      this.dialogLeaveIdeaVisible = false

      this.ideaToLeaveId = null
    },

    showDialogInviteUser (idea) {
      if (!this.isSubscribed || this.isUsersLimitExceeded) {
        this.setDialogUpgradeState(true)

        return
      }

      this.dialogIdeaInviteCollaboratorVisible = true

      this.ideaToInvite = idea
    },

    showDialogSettingsIdea () {
      // this.setDialogIdeaSettingsState(true)
      this.$router.push({ name: 'idea-settings' })
    },

    hideDialogIdeaInviteCollaborator () {
      this.dialogIdeaInviteCollaboratorVisible = false

      setTimeout(function () {
        this.ideaToInvite = null
      }.bind(this), 500)
    },

    onSwitchIdea (ideaId = null) {
      if (this.$store.state.user.ideasCount <= this.ideasInPlan || this.isTemplateAdmin) {
        const id = ideaId || this.ideaToSwitchId

        if (!id) return
        this.$refs.dialogSwitchIdea.loading = true

        this.switchIdea(id)
          .then(_ => {
            // this.$router.push({ name: 'home', query: { openIdeaSettings: this.openIdeaSettingsAfterSwitch } })
            this.settings ? this.$router.push({ name: 'idea-settings' }) : this.$router.push({ name: 'home' })
            this.hideDialogSwitchIdea()
          })
          .catch(_ => {
            this.$refs.dialogSwitchIdea.loading = false
          })
      }
    },

    removeIdea () {
      const id = this.ideaToRemoveId
      this.$refs.dialogRemoveIdea.loading = true

      if (!id) return

      this.deleteIdea(id).then((response) => {
        if (response.status === 200) {
          this.$refs.dialogRemoveIdea.loading = false
          this.hideDialogRemoveIdea()

          if (this.activeIdeaId === id) {
            this.$store.commit('idea/resetState')
          }
          this.intercomUpdateUser()
          this.$intercom.trackEvent('Idea deleted')
        }
      }).then(_ => {
        const deletedIdeaId = this.activeIdeaId
        this.$gtm.trackEvent({
          event: 'ideaDeleted'
        })

        if (deletedIdeaId === 0 && this.getIdeas.length !== 0) {
          const nextIdeaId = this.getIdeas[0].id
          this.switchIdea(nextIdeaId)
            .then(_ => {
              this.$router.push({ name: 'home' })
            })
        } else if (this.getIdeas.length === 0) {
          this.$router.push({ name: 'select-idea' })
        }
      })
    },

    copyIdea () {
      this.$refs.dialogCopyIdea.loading = true

      this.duplicateIdea(this.ideaToCopyId)
        .then(_ => {
          this.$refs.dialogCopyIdea.loading = false
          this.hideDialogCopyIdea()
          this.activeTab = 'myIdeas'

          this.$intercom.trackEvent('Idea copied')
          this.$gtm.trackEvent({
            event: 'ideaCopied'
          })
          this.intercomUpdateUser()
          scrollToTop()
        })
    },

    onLeaveIdea () {
      this.$refs.dialogLeaveIdea.loading = true

      this.leaveIdea(this.ideaToLeaveId)
        .then(_ => {
          this.$refs.dialogLeaveIdea.loading = false

          if (this.activeIdeaId === this.ideaToLeaveId) {
            this.$store.commit('idea/resetState')
          }

          this.hideDialogLeaveIdea()
        })
    },

    intercomUpdateUser () {
      this.$intercom.update({
        IdeaCount: this.getIdeasCount
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-min-height {
  min-height: 400px;
}

.template-manager {
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  background-color: #ffba00;
  border-radius: 5px;
  padding: 2px 10px;
}

.idea-count {
  background-color: rgba(11, 66, 176, 0.1);
  color: #0b42b0;
  font-family: outfit-regular;
  padding: 2px 12px;
  border-radius: 14px;
  margin-left: 10px;

  &.limit-reached {
    color: $color-red;
    background-color: #ffd1d1;
  }
}

.tab-description {
  opacity: 0.5;
  font-size: 18px;
  margin: 30px 0 10px;
}

.upgrade {
  color: white;
  text-decoration: none;
  background-color: #0060ee;
  border-radius: 3px;
  vertical-align: middle;
  padding: 0 10px;
}
</style>
