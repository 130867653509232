<template>
  <ib-dialog
    :title="$t('pages.users.inviteCollaboratorToIdea')"
    :visible="visible"
    width="750px"
    top="8vh"
    @close="$emit('close')"
    @closed="onClosed"
  >
    <div v-if="idea" class="d-flex flex-column align-items-center mb-4">
      <ib-idea-avatar :idea="idea" size="xl" class="mb-2" />
      <h2 class="m-0">
        {{ idea.name }}
      </h2>
      <h5 class="m-0 text-muted">
        {{ idea.catalogueIdea ? idea.catalogueIdea.name : `${$t('other')} ${idea.catalogueCategory.name}` }}
      </h5>
    </div>

    <el-row class="mb-4">
      <el-col>
        <el-form
          ref="inviteForm"
          class="invite-form"
          :model="form"
          :rules="rules"
          @submit.prevent.native
        >
          <el-row>
            <el-col>
              <el-form-item prop="email">
                <el-input
                  v-model="form.email"
                  :placeholder="$t('pages.users.enterValidEmailAddress')"
                />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                prop="note"
                class="mb-0"
              >
                <el-input
                  v-model="form.note"
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 3}"
                  :placeholder="$t('pages.users.invitationMessage')"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>

    <!-- Access chooser -->
    <access-chooser v-model="role" />
    <!-- /Access chooser -->

    <template #footer>
      <ib-divider class="mb-4" block />
      <ib-dialog-footer
        :loading="loading"
        :submit-btn-text="$t('pages.users.sendInvitation')"
        @submit="onSubmit"
        @cancel="$emit('close')"
      />
    </template>
  </ib-dialog>
</template>

<script>
import AccessChooser from '@/views/Home/InvitedUsers/Components/AccessChooser'
import UserRoleEnum from '@/constants/UserRoleEnum'
import rules from '@/views/Home/InvitedUsers/data/validationRules'

import { mapActions } from 'vuex'

export default {
  name: 'DialogIdeaInviteCollaborator',

  components: {
    AccessChooser
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },

    idea: {
      validator: value => typeof value === 'object' || value === null,
      required: true
    }
  },

  data () {
    return {
      loading: false,
      form: {
        email: null,
        note: null,
        ideas: [],
        single: true
      },
      role: UserRoleEnum.GUEST,
      rules
    }
  },

  methods: {
    ...mapActions('user/collaborators', ['inviteCollaborator']),

    onSubmit () {
      this.$refs.inviteForm.validate((valid) => {
        if (!valid) return
        this.loading = true
        this.formatIdeas()

        this.inviteCollaborator(this.form).then((response) => {
          this.dialogInviteUserVisible = false
          this.$emit('close')
          this.loading = false

          switch (response.status) {
            case 201:
              this.$notify({ title: this.$t('success'), message: this.$t('pages.users.invitationSent'), type: 'success' })
              break
            case 202:
              this.$notify({ title: this.$t('success'), message: this.$t('pages.users.invitationSentNoEmail'), type: 'success' })
              break
            case 208:
              this.$notify({ title: this.$t('warning'), message: this.$t('pages.users.userHasAlreadyInvited'), type: 'warning' })
              break
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },

    formatIdeas () {
      const { id, name } = this.idea
      this.form.ideas = []
      this.form.ideas.push({
        id,
        name,
        role: this.role
      })
    },

    onClosed () {
      this.form = {
        email: null,
        note: null,
        ideas: [],
        single: true
      }
      this.role = UserRoleEnum.GUEST
    }
  }
}
</script>

<style scoped lang="scss">
.invite-form {
  ::v-deep .el-input {
    max-width: none;
  }
}
</style>
